import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Your Shopify store to your preference`}</h2>
    <p>{`Below you’ll find a feature comparison between our three most popular design packages for Shopify stores,
the basic, standard and premium. All packages are designed with savings in mind.
The basic will give you a great point of start to build a brand on shopify.
The standard will give you a great foundation from which to build upon.
The premium will instantly take your site to that next level.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      